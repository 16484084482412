import getProductVariant from './utils/getProductVariant';

export const publicRoutes = [
  '/login',
  '/logout',
  '/account/reset',
  '/account/forgot',
  '/account/confirm',
];

export const passwordPolicyInfo =
  'Passwords must be at least 8 characters and should include at least 1 number and 1 uppercase letter.';

export const region = 'eu-west-2';
export const userPoolId = 'eu-west-2_KzmXKZCGO';
export const userPoolWebClientId = '7lb64r2dbb980fb5j8dnlog5kl';

export const yearGroups = [
  { name: 'Nu', scottishName: null },
  { name: 'EYFS', scottishName: 'Nursery' },
  { name: 'Y1', scottishName: 'P1' },
  { name: 'Y2', scottishName: 'P2' },
  { name: 'Y3', scottishName: 'P3' },
  { name: 'Y4', scottishName: 'P4' },
  { name: 'Y5', scottishName: 'P5' },
  { name: 'Y6', scottishName: 'P6' },
  { name: 'Y7', scottishName: 'P7' },
  { name: 'Y8', scottishName: 'S1' },
  { name: 'Y9', scottishName: 'S2' },
  { name: 'Y10', scottishName: 'S3' },
  { name: 'Y11', scottishName: 'S4' },
  { name: 'Y12', scottishName: 'S5' },
  { name: 'Y13', scottishName: 'S6' },
  { name: 'Y14', scottishName: null },
];

export const variants = {
  SMIDPRIMARY: {
    title: 'SMID Primary',
    url: 'https://dashboard.smidreport.com',
    fromAddress: 'noreply@smidreport.com',
  },
  TRACIWR: {
    title: 'Traciwr',
    url: 'https://dashboard.traciwr.com',
    fromAddress: 'noreply@traciwr.com',
  },
  TLEVELTRACKER: {
    title: 'T Level Tracker',
    url: 'https://dashboard.tleveltracker.com',
    fromAddress: 'noreply@tleveltracker.com',
  },
  EDRIAN: {
    title: 'Edrian',
    url: 'https://dashboard.edrian.co.uk',
    fromAddress: 'noreply@edrian.co.uk',
  },
};

export const defaultVariant = variants['TRACIWR'];

export const defaultErrorMessage =
  'Something went wrong, please try again later.';

export const defaultScoreSystems = [
  {
    name: 'Age (Year & Month)',
    options: Array.from(
      {
        length: 16,
      },
      (_, year) => {
        return Array.from(
          {
            length: 12,
          },
          (_, month) => {
            return {
              name: `${year} year${year !== 1 ? 's' : ''} ${month} month${
                month !== 1 ? 's' : ''
              }`,
              alternatives: [
                `${year}y${month}m`,
                `${year}.${
                  month > 0 ? month.toString().padStart(2, '0') : month
                }`,
                ...(month == 0 ? [year.toString()] : []),
              ],
            };
          }
        );
      }
    )
      .flat()
      .splice(1) // remove 0 years, 0 months
      .map((opt, index) => ({
        name: opt.name,
        precedence: index + 1,
        alternatives: opt.alternatives,
      })),
  },
  {
    name: 'Age Standardised Score',
    isFreeEntry: true,
    min: 10,
    max: 145,
    ranges: [
      { start: 65, end: 80, color: '#eb144c' },
      { start: 80, end: 95, color: '#ff6900' },
      { start: 95, end: 110, color: '#fcb900' },
      { start: 110, end: 125, color: '#0693e3' },
      { start: 125, end: 142, color: '#00d084' },
    ],
  },
];

export const attendanceSystem = {
  id: 'ATTENDANCE',
  name: 'Attendance Tile',
  isFreeEntry: true,
  min: 0,
  max: 100,
  options: [],
  ranges: [
    { start: 0, end: 83, color: '#a80000' },
    { start: 83, end: 94, color: '#e67039' },
    { start: 94, end: 100, color: '#00ff00' },
  ],
};

export const languageResources = {
  en: {
    translation: {
      key: 'test',
    },
  },
  cy: {
    translation: {
      // ['Manage Aspects']: 'Manage Aspects Welsh',
      ['Home']: 'Cartref',
      ['Manage Users']: 'Rheoli Defnyddwyr',
      ['Data Captures']: 'Capsiynau Data',
      ['Help & Support']: 'Cymorth a Chymorth Technegol',
      ['Settings']: 'Gosodiadau',
      ['Log Out']: 'Allgofnodi',
      ['Welcome to']: 'Croeso I (school name as it is)',
      ['Quick Actions']: 'Camau Cyflym',
      ['Manage Aspects']: 'Rheoli Agweddau',
      ['Manage Student Groups']: 'Rheoli Grwpiau Myfyrwyr',
      ['Score Systems']: 'Sistemau Sgorio',
      ['Data Captures']: 'Capsiynau Data',
      ['Manage Students']: ' Rheoli Myfyrwyr',
      ['Template View']: 'Golwg Templed',
      ['Import Results']: 'Mewnforio Canlyniadau',
      ['Your Notifications']: 'Eich Hysbysiadau',
      ["Your school hasn't sent any notifications"]:
        'Nid yw eich ysgol wedi anfon unrhyw hysbysiadau',
      ['When they do, those notifications will show up here.']:
        "Pan fyddan nhw'n wneud hynny, bydd y hysbysiadau hynny'n ymddangos yma.",
      ['Manage Aspects']: 'Rheoli Agweddau',
      ['Search']: 'Chwilio',
      ['New Aspect']: 'Agwedd Newydd',
      ['Aspect']: 'Agwedd',
      ['Descriptor']: 'Disgrifydd',
      ['Linked Classes']: 'Dosbarthiadau Cysylltiedig',
      ['Associated Staff']: 'Staff Cysylltiedig',
      ['No. Results']: 'Dim Canlyniadau',

      ['School Groups']: 'Grwpiau Ysgol',
      ['Form Groups']: 'Grwpiau Ffurf',
      ['Year Groups']: 'Grwpiau Blwyddyn',
      ['Import Groups']: 'Mewnforio Grwpiau',
      ['Import Student-Group Links']: 'Mewnforio Dolenni Myfyriwr-Grŵp',
      ['Need help getting started?']: 'Angen help i ddechrau?',
      ['Download import group CSV file example']:
        'Llwytho i lawr enghraifft o ffeil CSV ',
      ['Download import group links CSV file example']:
        'Llwytho i lawr enghraifft o ffeil ',
      ['Manage Student Groups']: 'Rheoli Grwpiau Myfyrwyr',
      ['Student Group']: 'Grŵp Myfyrwyr',
      ['Search']: 'Chwilio',
      ['New Group']: 'Grŵp Newydd',
      ['Linked students']: 'Myfyrwyr cysylltiedig',
      ['Students']: 'Myfyrwy',

      ['Manage Score Systems']: 'Rheoli Systemau Sgorio',
      ['Search']: 'Chwilio',
      ['New System']: 'System Newydd',
      ['System Name']: "Enw'r System",
      ['Allow custom input?']: 'Caniatáu mewnbwn cysefin',
      ['Available options']: 'Opsiynau ar gael',
      ['No. Results']: 'Dim Canlyniada',

      ['Manage Captures']: 'Rheoli Capsiynau',
      ['Search']: 'Chwilio',
      ['New Capture']: 'Capsiynu Newydd',
      ['Export All']: "Allforio'r Cyfan",
      ['Capture title']: 'Teitl y Capsiynu',
      ['Start date']: 'Dyddiad dechrau',
      ['End date']: 'Dyddiad gorffen',
      ['Aspects & Score Systems']: 'Agweddau a Systemau Sgori',

      ['Upload Students']: 'Llwytho Myfyrwyr i Fyny',
      ['Upload Single Student']: 'Llwytho Myfyriwr Unigol i Fyny',
      ['Use the select CSV button for multiple students']:
        'Defnyddiwch y botwm dewis ',
      ['Select CSV']: 'Dewiswch CSV',
      ['Need help getting started?']: 'Angen help i ddechrau?',
      ['Download a sample CSV file']: 'Llwytho i lawr ffeil CSV arallgopi',
      ['Previously Uploaded Students']: 'Myfyrwyr a Lwythwyd Ymlaen Gynt',
      ['Search']: 'Chwilio',
      ['Forename']: 'Enw Cyntaf',
      ['Surname']: 'Cyfenw',
      ['Gender']: 'Rhyw',
      ['Admission Number']: 'Rhif Derbyn',
      ['Year']: 'Blwyddyn',
      ['Form Group']: 'Grŵp Ffurflen',
      ['Dashboard']: 'Panel Rheolaet',

      ['Use Template']: 'Defnyddio Templed',
      ['You must select 1 capture to view data for this template']:
        'Rhaid i chi ddewis 1',
      ['Select a template']: 'Dewis temple',

      ['Manage Users']: 'Rheoli Defnyddwyr',
      ['Search']: 'Chwilio',
      ['Title']: 'Teitl',
      ['Forename']: 'Enw Cyntaf',
      ['Surname']: 'Cyfenw',
      ['Account type']: 'Math y Cyfrif',
      ['Staff code']: 'Côd Staff',
      ['Email']: 'E-bost',
      ['Activated?']: "Wedi'i actifadu",
      ['Last login']: 'Mewngofnodi diwetha',

      ['Secure Upload']: 'Llwytho i fyny yn ddiogel',
      ['User Guide']: 'Cymorth Defnyddiwr',
      ['Contact Us']: 'Cysylltwch â Ni',
      ['Send the data to us using our secure upload below and we will handle the process for you!']:
        "Gan anfon y data atom ni gan ddefnyddio ein llwytho i fyny diogel isod, a byddwn yn delio â'r broses ar eich rhan chi",
      ['Take a look at our user guide for everything you need to know about getting  started with Traciwr!']:
        'Edrychwch ar ein canllaw defnyddiwr am bopeth y mae angen i chi ei wybod am ddechrau gyda Traciwr',
      [`If you have any questions, please send an email to info@traciwr.com and we'll be happy to help.`]:
        "Os oes gennych unrhyw gwestiynau, anfonwch e-bost at info@traciwr.com a byddwn yn hapus i'ch helpu.",
      ['Select File']: 'Dewis Ffeil',
      ['Download User Guide']: 'Llwytho i Lawr Canllaw Defnyddiwr',
      ['Support Files']: 'Ffeiliau Cymorth',
      ['Search']: 'Chwilio',
      ['Filename']: "Enw'r Ffeil",
      ['Upload Date']: 'Dyddiad Llwytho i Fyn',
      ['Tutorials']: 'Tiwtorialau',
    },
  },
};

export const USER_NOT_FOUND = `You do not have an active account with us, please email ${
  getProductVariant().email
} for more information.`;
export const USER_NOT_ACTIVATED = `Your account must be activated before you can access ${
  getProductVariant().title
}. Contact your school administrator.`;
export const LOGIN_DISABLED = `Your subscription is no longer active. Please email ${
  getProductVariant().email
} for more information.`;

export const S3_CSV_BUCKET = 'smidprime-csv-uploads';
export const S3_VIDEOS_BUCKET = 'smidprime-support-videos';

export const attendanceCodes = [
  {
    Id: '-',
    Mark: '-',
    Description: 'No Mark',
    MeaningDescription: 'No mark',
    ShortMeaningDescription: 'No Mark',
    PhysicalMeaning: 'NOMARK',
  },
  {
    Id: '/',
    Mark: '/',
    Description: 'Present',
    MeaningDescription: 'Present',
    ShortMeaningDescription: 'Present',
    PhysicalMeaning: 'IN',
  },
  {
    Id: '\\',
    Mark: '\\',
    Description: 'Present',
    MeaningDescription: 'Present',
    ShortMeaningDescription: 'Present',
    PhysicalMeaning: 'IN',
  },
  {
    Id: 'B',
    Mark: 'B',
    Description: 'Educated off site (not Dual reg.)',
    MeaningDescription: 'Approved Edu. Activity',
    ShortMeaningDescription: 'Approved',
    PhysicalMeaning: 'OUT',
  },
  {
    Id: 'C',
    Mark: 'C',
    Description: 'Other authorised circumstances',
    MeaningDescription: 'Authorised Absence',
    ShortMeaningDescription: 'Authorised',
    PhysicalMeaning: 'OUT',
  },
  {
    Id: 'D',
    Mark: 'D',
    Description: 'Dual registration',
    MeaningDescription: 'Attendance not required',
    ShortMeaningDescription: 'Not Required',
    PhysicalMeaning: 'OUT',
  },
  {
    Id: 'E',
    Mark: 'E',
    Description: 'Excluded',
    MeaningDescription: 'Authorised Absence',
    ShortMeaningDescription: 'Authorised',
    PhysicalMeaning: 'OUT',
  },
  {
    Id: 'G',
    Mark: 'G',
    Description: 'Family holiday (Not agreed)',
    MeaningDescription: 'Unauthorised Absence',
    ShortMeaningDescription: 'Unauthorised',
    PhysicalMeaning: 'OUT',
  },
  {
    Id: 'H',
    Mark: 'H',
    Description: 'Family holiday (agreed)',
    MeaningDescription: 'Authorised Absence',
    ShortMeaningDescription: 'Authorised',
    PhysicalMeaning: 'OUT',
  },
  {
    Id: 'I',
    Mark: 'I',
    Description: 'Illness',
    MeaningDescription: 'Authorised Absence',
    ShortMeaningDescription: 'Authorised',
    PhysicalMeaning: 'OUT',
  },
  {
    Id: 'L',
    Mark: 'L',
    Description: 'Late (before registers closed)',
    MeaningDescription: 'Present',
    ShortMeaningDescription: 'Late',
    PhysicalMeaning: 'LATE',
  },
  {
    Id: 'M',
    Mark: 'M',
    Description: 'Medical/Dental appointments',
    MeaningDescription: 'Authorised Absence',
    ShortMeaningDescription: 'Authorised',
    PhysicalMeaning: 'OUT',
  },
  {
    Id: 'N',
    Mark: 'N',
    Description: 'No reason yet provided for absence',
    MeaningDescription: 'Unauthorised Absence',
    ShortMeaningDescription: 'Unauthorised',
    PhysicalMeaning: 'OUT',
  },
  {
    Id: 'O',
    Mark: 'O',
    Description: 'Unauthorised Abs',
    MeaningDescription: 'Unauthorised Absence',
    ShortMeaningDescription: 'Unauthorised',
    PhysicalMeaning: 'OUT',
  },
  {
    Id: 'P',
    Mark: 'P',
    Description: 'Approved sporting activity',
    MeaningDescription: 'Approved Edu. Activity',
    ShortMeaningDescription: 'Approved',
    PhysicalMeaning: 'OUT',
  },
  {
    Id: 'R',
    Mark: 'R',
    Description: 'Religious observance',
    MeaningDescription: 'Authorised Absence',
    ShortMeaningDescription: 'Authorised',
    PhysicalMeaning: 'OUT',
  },
  {
    Id: 'S',
    Mark: 'S',
    Description: 'Study leave',
    MeaningDescription: 'Authorised Absence',
    ShortMeaningDescription: 'Authorised',
    PhysicalMeaning: 'OUT',
  },
  {
    Id: 'T',
    Mark: 'T',
    Description: 'Traveller absence',
    MeaningDescription: 'Authorised Absence',
    ShortMeaningDescription: 'Authorised',
    PhysicalMeaning: 'OUT',
  },
  {
    Id: 'V',
    Mark: 'V',
    Description: 'Educational visit or trip',
    MeaningDescription: 'Approved Edu. Activity',
    ShortMeaningDescription: 'Approved',
    PhysicalMeaning: 'OUT',
  },
  {
    Id: 'W',
    Mark: 'W',
    Description: 'Work experience',
    MeaningDescription: 'Approved Edu. Activity',
    ShortMeaningDescription: 'Approved',
    PhysicalMeaning: 'OUT',
  },
  {
    Id: '#',
    Mark: '#',
    Description: 'Planned whole or partial school closure',
    MeaningDescription: 'Attendance not required',
    ShortMeaningDescription: 'Not Required',
    PhysicalMeaning: 'OUT',
  },
  {
    Id: 'Y',
    Mark: 'Y',
    Description: 'Unable to attend due to exceptional circumstances',
    MeaningDescription: 'Attendance not required',
    ShortMeaningDescription: 'Not Required',
    PhysicalMeaning: 'OUT',
  },
  {
    Id: 'X',
    Mark: 'X',
    Description: 'Non-compulsory school age absence',
    MeaningDescription: 'Attendance not required',
    ShortMeaningDescription: 'Not Required',
    PhysicalMeaning: 'OUT',
  },
  {
    Id: 'Z',
    Mark: 'Z',
    Description: 'DfES Z: Pupil not on roll',
    MeaningDescription: 'Attendance not required',
    ShortMeaningDescription: 'Not Required',
    PhysicalMeaning: 'OUT',
  },
  {
    Id: 'F',
    Mark: 'F',
    Description: 'Extended family holiday (agreed)',
    MeaningDescription: 'Authorised Absence',
    ShortMeaningDescription: 'Authorised',
    PhysicalMeaning: 'OUT',
  },
  {
    Id: 'J',
    Mark: 'J',
    Description: 'Interview',
    MeaningDescription: 'Approved Edu. Activity',
    ShortMeaningDescription: 'Approved',
    PhysicalMeaning: 'OUT',
  },
  {
    Id: 'U',
    Mark: 'U',
    Description: 'Late (after registers closed)',
    MeaningDescription: 'Unauthorised Absence',
    ShortMeaningDescription: 'Unauthorised',
    PhysicalMeaning: 'LATE',
  },
  {
    Id: '7',
    Mark: '7',
    Description: 'Illness due to Covid 19',
    MeaningDescription: 'Authorised Absence',
    ShortMeaningDescription: 'Authorised',
    PhysicalMeaning: 'OUT',
  },
  {
    Id: '8',
    Mark: '8',
    Description: 'Self-isolating due to Covid 19',
    MeaningDescription: 'Attendance not required',
    ShortMeaningDescription: 'Not Required',
    PhysicalMeaning: 'OUT',
  },
  {
    Id: '9',
    Mark: '9',
    Description: 'Shielding due to Covid 19',
    MeaningDescription: 'Attendance not required',
    ShortMeaningDescription: 'Not Required',
    PhysicalMeaning: 'OUT',
  },
];

export const defaultAoleSystem = {
  name: 'default aole system',
  options: [
    {
      name: '1',
      precedence: 3,
      color: '#157002',
    },
    {
      name: '2',
      precedence: 2,
      color: '#f29705',
    },
    {
      name: '3',
      precedence: 1,
      color: '#cf1b0e',
    },
  ],
};
